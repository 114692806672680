<template>
  <div>
    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center flex-column align-center">
        <div class="d-flex align-self-start pt-2 pb-2">
          <Breadcrumb class="conteudos_tematicos_section_bc-color">
            <template v-slot:breadcrumbLinks>
              <router-link
                tag="p"
                :to="{ name: link.href }"
                v-for="(link, idx) in breadcrumbItems"
                :key="idx"
              >
                {{ link.text }}
              </router-link>
            </template>
          </Breadcrumb>
        </div>
        <div
          class="d-flex align-self-center justify-center align-center mt-md-5"
        >
          <img
            width="40%"
            src="../../assets/conteudos_casas_home_04.png"
            alt=""
          />
        </div>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card
              borderColor="#426E51"
              title="Desterritorialização e Reterritorialização"
              titleColor="#426E51"
            >
              <template v-slot:conteudo>
                <p>
                  Falar sobre territorialização, desterritorialização e
                  reterritorialização é pensar na formação e na relação que a
                  pessoa tem com o território e o seu entorno.
                </p>
                <p>
                  Isso porque a formação do território é o resultado de anos de
                  relações e ações, cuidados contínuos e construção do próprio
                  social, pois, ao estar em um espaço, a pessoa dele se
                  apropria, concreta ou abstratamente, "territorializando-o"
                  (RAFFESTIN, 1993, p. 143). Nesse sentido, é importante pensar
                  como isso acontece com as pessoas atingidas pelos
                  empreendimentos minerários.
                </p>
                <p>
                  A desterritorialização que foi vivida por atingidos e
                  atingidas é de caráter forçoso, não ocorre voluntária e
                  espontaneamente, muito menos a partir de uma decisão informada
                  e na qual havia alternativas possíveis de serem consideradas.
                  Ocorre a partir da impossibilidade de se manterem no lugar de
                  origem, que ficou totalmente descaracterizado e cujas
                  condições de habitabilidade foram drasticamente retiradas.
                </p>
                <p>
                  De forma violenta, o território lhes foi tirado, acarretando
                  em uma quebra de vínculo com o lugar e implicando em uma
                  quebra de controle das territorialidades pessoais ou
                  coletivas, de fratura no acesso a territórios econômicos,
                  simbólicos, a recursos, a bens (HAESBAERT, 2004).
                </p>
                <p>
                  Mais do que mudança do local de origem, a desterritorialização
                  consiste no rompimento dos vínculos e afetos ali
                  estabelecidos. As relações de amizade, de vizinhança, de
                  trabalho, assim como o sentido de comunidade, ficam
                  comprometidos. Essa desestruturação gera sofrimento e mudanças
                  profundas no modo de viver de cada um e da própria comunidade.
                  Percebemos as profundas alterações nas dinâmicas de
                  sociabilidade nas falas dos atingidos e atingidas<sup>1</sup>:
                </p>
              </template>
            </Card>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="d-flex justify-center align-center flex-column"
        >
          <img
            width="80%"
            src="../../assets/conteudos_casas_desterritorio_01.png"
          />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Bento Rodrigues. Foto: Flora Passos, 2018.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__margin-bottom">
      <v-row
        class="d-flex justify-md-start align-md-start justify-center align-center"
      >
        <v-col
          cols="10"
          md="7"
          class="d-flex justify-center align-center border__image"
          style="z-index: 10;"
        >
          <p style="z-index: 15; text-align: center" class="ma-0">
            “não cheguei a ir nas festas realizadas após o rompimento, porque
            não me sinto bem. Não gosto de retornar à comunidade, todas as vezes
            que passei por lá, me dá uma coisa ruim. Eu vejo o lugar onde era a
            casa de minha mãe e sinto uma coisa ruim, de imaginar casas
            inteiras, vidas, inundadas pela Samarco”.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__margin-bottom">
      <v-row
        class="d-flex justify-md-end align-md-end align-center justify-center"
      >
        <v-col
          cols="10"
          md="7"
          class="d-flex justify-center align-center border__image2"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0">
            “num dia típico, em Bento, era como Natal ou ano novo, a gente ia na
            casa de todos, comia na casa das tias, uma brigava por ter dormido
            na casa de outra tia. A gente ficava conversando, ficavam na praça,
            sentados no banco de pedra conversando... A gente visitava a todos
            em suas casas e quando percebia já estava na hora de ir embora.
            <strong
              >Agora não tem mais nada, agora perdeu o sentido... Antes tinha um
              lugar para ir</strong
            >. Sinto uma revolta, pois é como se tivesse tirado um pedaço de
            mim, de minha história”.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__margin-bottom">
      <v-row
        class="d-flex justify-md-start align-md-start justify-center align-center"
      >
        <v-col
          cols="10"
          md="7"
          class="d-flex justify-center align-center border__image3"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0">
            “Eu sinto muita diferença no convívio das pessoas. Muita coisa
            mudou. As mesmas pessoas com quem eu conversava antes, hoje nem me
            cumprimentam. Parece que o rompimento virou a cabeça das pessoas”.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__margin-bottom">
      <v-row
        class="d-flex justify-md-end align-md-end align-center justify-center"
      >
        <v-col
          cols="10"
          md="7"
          class="d-flex justify-center align-center border__image4"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0">
            “Em Paracatu de Cima, antes do rompimento da barragem, eu era muito
            próximo da comunidade. Me encontrava muito com os meus primos, ia
            muito na casa dos vizinhos em grupo de 5 ou 6 amigos ou primos, para
            tomar café. Costumava sair muito para jogar bola, pescar ou andar no
            mato à toa para passar o tempo. Hoje em dia, após o rompimento da
            barragem, eu me afastei um pouco de meus amigos e primos, pois é
            muito difícil de a gente se encontrar”.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__margin-bottom">
      <v-row
        class="d-flex justify-md-start align-md-start justify-center align-center"
      >
        <v-col
          cols="10"
          md="7"
          class="d-flex justify-center align-center border__image5"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0">
            “Eu sempre vivi na roça. Aprendi a plantar, fazer carvão, mexer com
            criação, tirar leite, fazer queijo.
            <strong>Vivi algum tempo na cidade, mas vivi mais na roça</strong>.
            E também arrumava uma moto, um carro, para as pessoas mesmo da roça,
            da comunidade, depois das 15h, eu fazia serviços de mecânica, de
            solda. Depois do rompimento da barragem, tudo isso acabou”.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="8" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  As consequências do dia do rompimento não se encerram naquele
                  dia 05 de novembro de 2015. A dinâmica criminosa que
                  desrespeita a estrutura social impacta de diversas maneiras a
                  vida das pessoas que tiveram suas comunidades levadas. Há
                  parcela significativa de famílias que segue residindo ou que
                  retornou para as comunidades de origem, ainda que
                  descaracterizadas, com suspeitas de contaminação ambiental,
                  trânsito de maquinário e pessoas desconhecidas e sem as redes
                  de interação social antes construídas. Algo os impele a estar
                  ali. Assim, entre os pleitos reiterados está a
                  disponibilização de energia elétrica, banheiros e melhorias na
                  segurança.
                </p>
                <p>
                  Os “Loucos por Bento” se reúnem todo domingo na comunidade que
                  hoje é terra arrasada. Algumas famílias voltaram a residir,
                  plantar e manter suas criações em Paracatu de Baixo. As
                  missas, procissões e enterros voltaram a acontecer nas
                  comunidades.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__margin-bottom">
      <v-row
        class="d-flex justify-md-end align-md-end align-center justify-center"
      >
        <v-col
          cols="10"
          md="7"
          class="d-flex justify-center align-center border__image6"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0">
            “Tem uma tia minha que prefere ir a Bento nos finais de semana.
            Mesmo destruído, a gente prefere estar em Bento do que em Mariana,
            por causa do preconceito que a gente sofre”.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__margin-bottom">
      <v-row
        class="d-flex justify-md-start align-md-start justify-center align-center"
      >
        <v-col
          cols="10"
          md="7"
          class="d-flex justify-center align-center border__image7"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0">
            “Eu sempre vivi na roça. Aprendi a plantar, fazer carvão, mexer com
            criação, tirar leite, fazer queijo.
            <strong>Vivi algum tempo na cidade, mas vivi mais na roça</strong>.
            E também arrumava uma moto, um carro, para as pessoas mesmo da roça,
            da comunidade, depois das 15h, eu fazia serviços de mecânica, de
            solda. Depois do rompimento da barragem, tudo isso acabou”.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="8" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  A verdade é que “Territórios existem e são construídos (e
                  desconstruídos) nas mais diversas escalas...” (SOUZA, 2006, p.
                  81). Mas, nesse sentido, entende-se que essas escalas não se
                  limitam ao domínio físico do espaço, são também sentimentais,
                  temporais e psicológicas. Não apenas um local, pois possuem ao
                  mesmo tempo funções simbólicas e funcionais, contêm em si,
                  implícita e explicitamente, as dimensões de um lar. Mas o lar
                  não é somente a casa, são também as pessoas que vivem nele, os
                  vizinhos, a vista da janela, a plantação do terreno, as
                  árvores, o rio e seus braços, os animais, os sorrisos
                  compartilhados, os passos até a igreja e os calendários de
                  celebrações. Lar é a segurança de se sentir feliz em um
                  território criado por eles e para eles, lar é algo que deixou
                  de existir para diversas famílias. Assim, passa-se a entender
                  territorializar como a possibilidade de se apropriar de um
                  espaço e torná-lo um lar, condição que só é possível se existe
                  autonomia da população.
                </p>
                <p>
                  Consequentemente, a promessa deste novo lar condiciona as
                  comunidades a um frequente rememorar quanto ao que existiu, em
                  grande expectativa ao que poderá ser reproduzido nas novas
                  comunidades:
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__margin-bottom">
      <v-row
        class="d-flex justify-md-end align-md-end align-center justify-center"
      >
        <v-col
          cols="10"
          md="7"
          class="d-flex justify-center align-center border__image8"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0">
            “O Novo Bento não será como o antigo. Minha avó acredita que não
            chega a conhecer o Novo Bento. Agora ela fica em casa o tempo todo,
            sem a animação de antes. Eu sonho que minha avó possa conhecer o
            Novo Bento, pra que ela seja mais feliz. Mas que ela mesma não tem
            nenhum sonho ou perspectiva pro Novo Bento”.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__margin-bottom">
      <v-row
        class="d-flex justify-md-start align-md-start justify-center align-center"
      >
        <v-col
          cols="10"
          md="7"
          class="d-flex justify-center align-center border__image9"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0">
            “Minha vida não tá como eu imaginava que seria. A vida virou uma
            bagunça, como se fosse um rio que estivesse descendo e mudasse o
            rumo de repente, ou como se tivessem tirado um pedaço de mim. Como
            se jogasse água fervendo e matasse uma planta”.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="12"
          md="5"
          class="d-flex justify-center align-center flex-column"
        >
          <img
            width="80%"
            src="../../assets/conteudos_casas_desterritorio_02.png"
          />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Bento Rodrigues. Foto: Flora Passos, 2019.
          </p>
        </v-col>
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  Exaustos e fragilizados, as decisões a que são chamados se
                  restringem principalmente sobre as dimensões internas e
                  revestimentos das próprias casas, condicionando as atingidas e
                  os atingidos a só colaborarem e intervirem de forma ativa na
                  reterritorialização na fase de conclusão do projeto, momento
                  em que já não é mais possível incluir as suas necessidades.
                </p>
                <p>
                  Essa ânsia pela tomada do espaço e construção do território se
                  encontra em todos os que aguardam as novas comunidades,
                  podendo ser verificada em falas como:
                </p>
                <p>
                  “A gente não quer um Bento lindo e maravilhoso, a gente quer
                  um Bento mais parecido com o antigo e principalmente manter a
                  relação de vizinhança” (CARVALHO, 2018, p. 4). E “A maioria
                  tem o mesmo objetivo: viver num cantinho, sossegado, na
                  rocinha lá, com os mesmos vizinhos, todo mundo unido, do mesmo
                  jeito” (ATINGIDOS DO BENTO, 2016, p. 9).
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  Tendo consciência de que o fundamental das antigas comunidades
                  são as pessoas e as relações, existe grande pressão das
                  atingidas e dos atingidos para restabelecer as relações de
                  vizinhança, visto que outros fatores relativos aos terrenos
                  impossibilitam a reprodução dos modos de vida anteriores.
                </p>
                <p>
                  Entre essas condições se encontram a inclinação e condições de
                  fertilidade do solo que não permitem as atividades produtivas
                  de plantio e criações, antes cernes do modo de vida local e
                  centrais na economia familiar.
                </p>
                <p>
                  A pouca autonomia para o planejamento da própria casa esbarra
                  em diversos desafios, que mostram a desconsideração da
                  identidade comunitária e familiar das pessoas, isso é
                  exemplificado pela própria importância do
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'conteudos_ser_atingido_tempos' }"
                    >fogão e forno a lenha</router-link
                  >
                  sempre centrais nos lares. Conforme reclamação recorrente das
                  mulheres candidatas ao reassentamento comunitário, na nova
                  dinâmica estrutural das casas, esses fogões são
                  desconsiderados com a instalação dos pré-fabricados, como
                  dispositivos de lazer e não determinante do modo de vida.
                </p>
                <p>
                  Além do
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'conteudo_soberania_beira_fogao' }"
                    >tempo de espera</router-link
                  >, passados mais de seis anos do rompimento da barragem, essa
                  recorrente violência se manifesta pela precarização das
                  relações. Espalhados pelo perímetro urbano, vivendo de
                  aluguel, sem contato com os amigos e sem um ponto central de
                  celebração de seus laços, aguardam ansiosamente a finalização
                  dos projetos e notícias sobre quem serão seus vizinhos, como
                  poderão vivenciar seus laços com o novo lar. E, mesmo, se esse
                  poderá ser chamado efetivamente de lar.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="d-flex justify-center align-center flex-column"
        >
          <img
            width="100%"
            src="../../assets/conteudos_casas_desterritorio_03.png"
          />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Reassentamento de Bento Rodrigues. Foto: Paula Zanardi, 2021.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__margin-bottom">
      <v-row
        class="d-flex justify-md-end align-md-end align-center justify-center"
      >
        <v-col
          cols="10"
          md="7"
          class="d-flex justify-center align-center border__image10"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0">
            “Hoje é um vai volta, sem saber quando vai acabar. Eu vivo em prol
            de saber quando tudo isso vai se resolver. Todos falam e falam,
            porém nada de concreto. Eu não vejo resultado de nada. Se não fosse
            a Cáritas e o Ministério Público, eu não tinha conseguido nada. Meu
            desejo é de que tudo se resolva mais rápido, para resolver a
            situação dos atingidos com depressão e que precisam mudar seus
            ritmos de vida. Eu desejo que tudo se resolva para acabar com essa
            novela que não tá boa”.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="8" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  Vale ressaltar que essa violência de espera se revela em
                  potências diferentes. Menos de dois meses antes do aniversário
                  de seis anos do rompimento da barragem, em 15 de setembro de
                  2021, foi colocado o primeiro tijolo no reassentamento
                  referente à comunidade de Paracatu de Baixo, em Mariana. Tal
                  evento, mesmo sendo veiculado como uma comemoração, reflete o
                  descaso com os modos de vida e com o próprio sofrimento das
                  diversas famílias e da comunidade, que não tem todos os seus
                  moradores contemplados pelo direito a uma nova moradia. E,
                  ainda, o desrespeito às decisões judiciais, que, após diversas
                  prorrogações, previa a entrega do projeto concluído em 27 de
                  fevereiro de 2021 (PASSOS; FERREIRA, 2021).
                </p>
                <p>
                  Sem previsão para entrega de suas casas, as comunidades
                  continuam a experienciar a desesperança e saudades, agravadas
                  por esses processos tão complexos e cruéis de
                  desterritorialização e reterritorialização, essa última
                  simbolizada pela negação de autonomia. Mesmo existindo a
                  possibilidade de fracasso de fazer do reassentamento um lar, a
                  força dessas pessoas, refletida em tantas lutas, mostra que
                  não haverá descanso enquanto não estiverem garantidos seus
                  direitos, sonhos e memórias.
                </p>
                <p style="text-align: center">
                  <strong
                    >Autoras: Ana Paula Santos Diniz<sup>2</sup>, Jéssica de
                    Paula Bueno da Silva<sup>3</sup>, Laís Jabace Maia
                    <sup>4</sup> e Paula Pflüger Zanardi <sup>5</sup></strong
                  >
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__margin-bottom">
      <v-row
        class="d-flex justify-md-start align-md-start justify-center align-center"
      >
        <v-col
          cols="10"
          md="7"
          class="d-flex justify-center align-center border__image11"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0">
            “Queria estar do jeito que era antes: tranquilo, fazendo as mesmas
            coisas que estava fazendo, cuidando das minhas criações, em minha
            casa, ajudando as pessoas. Não queria mais nada. Só queria ter a
            vida do jeito que estava, com minha família perto. Meus sonhos foram
            destruídos. Hoje não tenho mais a minha casa, tô morando na casa
            onde moravam meus pais. Não quero sair da comunidade, mas se todo
            mundo for pro Novo Paracatu, vou querer ir. Não sei se vai ter mesmo
            o reassentamento, na verdade, todo mundo tem essa dúvida”.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container pb-10 mt-4 mb-section">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12">
          <div class="inside__card">
            <p><sup>1</sup> Falas extraídas das tomadas de termo.</p>
            <p>
              <sup>2</sup> Bacharel em Direito pelas Faculdades Integradas do
              Oeste de Minas, mestra em Direitos Fundamentais pela Fundação
              Universidade de Itauna, doutoranda em Filosofia pela Universitat
              de Barcelona. Assessora Jurídica na ATI Mariana.
            </p>
            <p>
              <sup>3</sup> Bacharel em Direito pela FDMC, mestra em Direito pela
              UFOP, doutoranda em Direito pela UFMG. Assessora Jurídica na ATI
              Mariana.
            </p>
            <p>
              <sup>4</sup> Cientista Social pela UFMG, mestra e doutoranda em
              Planejamento Urbano e Regional (IPPUR/UFRJ). Coordenadora
              Operacional da Assessoria Técnica aos Atingidos e Atingidas pelo
              Rompimento da Barragem do Fundão em Mariana-MG desde 2020.
            </p>
            <p>
              <sup>5</sup> Cientista Social pela UFSC, mestra em Preservação do
              Patrimônio Cultural pelo IPHAN. Assessora técnica na ATI Mariana.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container pb-10 mt-4 mb-section">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12">
          <div class="inside__card">
            <p><strong>Referências Bibliográficas</strong></p>

            <p>
              A Sirene: para não esquecer. Ed. 0, fevereiro/2016. Disponível em:
              <a
                class="pink--text"
                style="text-decoration: none;"
                href="https://issuu.com/jornalasirene/docs/sirene_final_diogo_bx"
                target="_blank"
                >https://issuu.com/jornalasirene/docs/sirene_final_diogo_bx</a
              >.
            </p>
            <p>
              A Sirene: para não esquecer. Ed. 11, fevereiro/2017. Disponível
              em:
              <a
                class="pink--text"
                style="text-decoration: none;"
                href="https://issuu.com/jornalasirene/docs/a_sirene_fevereiro_issuu"
                target="_blank"
                >https://issuu.com/jornalasirene/docs/a_sirene_fevereiro_issuu</a
              >.
            </p>
            <p>
              Atingidos por Bento. A lavoura perto do Bento.
              <strong>A Sirene</strong>: para não esquecer. Ed. 3, junho/2016.
              Disponível em:
              <a
                class="pink--text"
                style="text-decoration: none;"
                href="https://issuu.com/jornalasirene/docs/a_sirene_ed3_junho_issuu"
                target="_blank"
                >https://issuu.com/jornalasirene/docs/a_sirene_ed3_junho_issuu</a
              >. CARVALHO, Juliana. A angústia da espera.
              <strong>Jornal O Lampião</strong>. Ed. 32, maio/2019. Disponível
              em:
              <a
                class="pink--text"
                style="text-decoration: none;"
                href="https://issuu.com/jornallampiao/docs/lampi_o_32"
                target="_blank"
                >https://issuu.com/jornallampiao/docs/lampi_o_32</a
              >.
            </p>
            <p>
              HAESBAERT, Rogério. Território e Multiterritorialidade: um debate.
              <strong>Geographia</strong> nº17; Brasil, 2004.
            </p>
            <p>
              LAPOUJADE, David. <strong>Existências Mínimas</strong>. São Paulo:
              N-1 Edições, 2017.
            </p>
            <p>
              RAFFESTIN, Claude. <strong>Por uma Geografia do poder</strong>.
              São Paulo: Ática, 1993.
            </p>
            <p>
              SILVA, A.F.; FAULHABER, Priscila. Bento Rodrigues e a memória que
              a lama não apagou: o despertar para o patrimônio na (re)construção
              da identidade no contexto pós-desastre.
              <strong>Boletim do Museu Paraense Emílio Goeldi</strong>. Ciências
              Humanas, v. 15, p. 1-15, 2020.
            </p>
            <p>
              SOUZA, Marcelo José Lopes de. O território: sobre espaço e poder,
              autonomia e desenvolvimento. In: CASTRO, Iná Elias de; GOMES,
              Paulo César da Costa; CORRÊA, Roberto Lobato. (Org.).
              <strong>Geografia</strong>: Conceitos e temas. 8ª ed. Rio de
              Janeiro: Bertrand Brasil, 2006.
            </p>
            <p>
              PASCOAL, G., MUNIZ, F. R. M., Quintão, M.; D’ NGELO JÚNIOR, M.).
              Direitos rompidos. A Sirene: para não esquecer. Ed. 16,
              julho/2017. Disponível em:
              <a
                class="pink--text"
                style="text-decoration: none;"
                href="https://issuu.com/jornalasirene/docs/a_sirene_ed._16_julho_issuu"
                target="_blank"
                >https://issuu.com/jornalasirene/docs/a_sirene_ed._16_julho_issuu</a
              >.
            </p>
            <p>
              PASSOS, Flora; FERREIRA, Lívia. Apenas um tijolo em Paracatu de
              Baixo: Renova diz atender 94 famílias mas obras inclui apenas onze
              casas. Brasil de Fato, 17/09/2021. Disponível em:
              <a
                class="pink--text"
                style="text-decoration: none;"
                href="https://www.brasildefatomg.com.br/2021/09/17/apenas-um-tijolo-em-paracatu-de-baixo#:~:text=No%20dia%2015%20de%20setembro,da%20luta%20da%20comunidade%20atingida"
                target="_blank"
                >https://www.brasildefatomg.com.br/2021/09/17/apenas-um-tijolo-em-paracatu-de-baixo#:~:text=No%20dia%2015%20de%20setembro,da%20luta%20da%20comunidade%20atingida</a
              >.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center pa-4">
        <v-col class="d-flex justify-center align-center">
          <v-btn
            :to="{ name: 'conteudos_tematicos_casas_vida_provisoria' }"
            color="#EDBD43"
          >
            <strong>Conhecer "A vida provisória"</strong></v-btn
          >
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
import Breadcrumb from "@/layout/Breadcrumb";

export default {
  components: { Card, Breadcrumb },
  data() {
    return {
      breadcrumbItems: [
        {
          text: "< Conteúdos Temáticos /",
          href: "conteudos_tematicos",
        },
        {
          text: "Casas /",
          href: "conteudos_tematicos_casas",
        },
        {
          text: "Desterritorialização",
          href: "conteudos_tematicos_casas_desterritorializacao",
        },
      ],
    };
  },
};
</script>

<style scoped>
.section__margin-bottom {
  margin-bottom: 3em;
}

.pointer {
  cursor: pointer;
}

p {
  font-size: 0.6em;
}
@media (max-width: 600px) {
  p {
    font-size: 1em;
  }
  section {
    margin-top: 0;
    margin-bottom: 0;
  }

  .border__image {
    background-color: #c1eef4;
    padding: 0;
    border-radius: 12px;
  }
  .border__image::before {
    display: none;
  }

  .border__image p {
    padding: 0 !important;
    max-width: 100% !important;
    font-size: 0.9em !important;
  }

  .border__image2 {
    background-color: #c1eef4;
    padding: 0;
    border-radius: 12px;
  }
  .border__image2::before {
    display: none;
  }

  .border__image2 p {
    padding: 0 !important;
    max-width: 100% !important;
    font-size: 0.9em !important;
  }

  .border__image3 {
    background-color: #c1eef4;
    padding: 0;
    border-radius: 12px;
  }
  .border__image3::before {
    display: none;
  }

  .border__image3 p {
    padding: 0 !important;
    max-width: 100% !important;
    font-size: 0.9em !important;
  }
  .border__image4 {
    background-color: #c1eef4;
    padding: 0;
    border-radius: 12px;
  }
  .border__image4::before {
    display: none;
  }

  .border__image4 p {
    padding: 0 !important;
    max-width: 100% !important;
    font-size: 0.9em !important;
  }

  .border__image5 {
    background-color: #c1eef4;
    padding: 0;
    border-radius: 12px;
  }
  .border__image5::before {
    display: none;
  }

  .border__image5 p {
    padding: 0 !important;
    max-width: 100% !important;
    font-size: 0.9em !important;
  }
  .border__image6 {
    background-color: #c1eef4;
    padding: 0;
    border-radius: 12px;
  }
  .border__image6::before {
    display: none;
  }

  .border__image6 p {
    padding: 0 !important;
    max-width: 100% !important;
    font-size: 0.9em !important;
  }

  .border__image7 {
    background-color: #c1eef4;
    padding: 0;
    border-radius: 12px;
  }
  .border__image7::before {
    display: none;
  }

  .border__image7 p {
    padding: 0 !important;
    max-width: 100% !important;
    font-size: 0.9em !important;
  }

  .border__image8 {
    background-color: #c1eef4;
    padding: 0;
    border-radius: 12px;
  }
  .border__image8::before {
    display: none;
  }

  .border__image8 p {
    padding: 0 !important;
    max-width: 100% !important;
    font-size: 0.9em !important;
  }

  .border__image9 {
    background-color: #c1eef4;
    padding: 0;
    border-radius: 12px;
  }
  .border__image9::before {
    display: none;
  }

  .border__image9 p {
    padding: 0 !important;
    max-width: 100% !important;
    font-size: 0.9em !important;
  }

  .border__image10 {
    background-color: #c1eef4;
    padding: 0;
    border-radius: 12px;
  }
  .border__image10::before {
    display: none;
  }

  .border__image10 p {
    padding: 0 !important;
    max-width: 100% !important;
    font-size: 0.9em !important;
  }

  .border__image11 {
    background-color: #c1eef4;
    padding: 0;
    border-radius: 12px;
  }
  .border__image11::before {
    display: none;
  }

  .border__image11 p {
    padding: 0 !important;
    max-width: 100% !important;
    font-size: 0.9em !important;
  }
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
}

.inside__card p {
  font-size: 0.5em;
}

.border__image {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudos_casas_vector_01.png");
  background-size: 100% 100%;
}

.border__image p {
  font-size: 0.6em;
  max-width: 80%;
  padding-right: 5em;
}
.border__image h3 {
  color: #e06919;
  font-size: 0.5em;
}

.border__image2 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image2::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudos_casas_vector_02.png");
  background-size: 100% 100%;
}

.border__image2 p {
  font-size: 0.6em;
  max-width: 80%;
  padding: 2em 0 2em 2em;
}

.border__image3 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image3::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudos_casas_vector_03.png");
  background-size: 100% 100%;
}

.border__image3 p {
  font-size: 0.6em;
  max-width: 80%;
  padding: 2em 2em 2em 0;
}

.border__image4 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image4::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudos_casas_vector_04.png");
  background-size: 100% 100%;
}

.border__image4 p {
  font-size: 0.6em;
  max-width: 80%;
  padding: 2em 0 2em 5em;
}

.border__image5 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image5::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudos_casas_vector_05.png");
  background-size: 100% 100%;
}

.border__image5 p {
  font-size: 0.6em;
  max-width: 80%;
  padding: 2em 4em 2em 0;
}

.border__image6 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image6::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudos_casas_vector_08.png");
  background-size: 100% 100%;
}

.border__image6 p {
  font-size: 0.6em;
  max-width: 80%;
  padding: 2em 0 2em 3em;
}

.border__image7 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image7::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudos_casas_vector_07.png");
  background-size: 100% 100%;
}

.border__image7 p {
  font-size: 0.6em;
  max-width: 80%;
  padding: 2em 4em 2em 0;
}

.border__image8 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image8::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudos_casas_vector_08.png");
  background-size: 100% 100%;
}

.border__image8 p {
  font-size: 0.6em;
  max-width: 80%;
  padding: 2em 0 2em 3em;
}

.border__image9 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image9::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudos_casas_vector_07.png");
  background-size: 100% 100%;
}

.border__image9 p {
  font-size: 0.6em;
  max-width: 80%;
  padding: 2em 4em 2em 0;
}

.border__image10 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image10::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudos_casas_vector_10.png");
  background-size: 100% 100%;
}

.border__image10 p {
  font-size: 0.6em;
  max-width: 80%;
  padding: 2em 0 2em 5em;
}

.border__image11 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image11::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudos_casas_vector_11.png");
  background-size: 100% 100%;
}

.border__image11 p {
  font-size: 0.6em;
  max-width: 80%;
  padding: 2em 4em 2em 0;
}
</style>
